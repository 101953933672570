import { Statsig } from "statsig-react";

import { getUtmParamsForStatsig } from "./customStatSigHooks";
import { brand } from "./envFunctions";
import { log } from "./logging/logger";

let initStatsigObject: InitStatsig = {
  page_title: "",
};

export enum TRACKING_CATEGORIES {
  navigation = "navigation",
  interaction = "interaction",
  view = "view",
}

export enum JOKES_CLICK_LOCATION {
  joke_title = "title",
  joke_image = "image",
  joke_action_button = "joke_action_button",
  joke_card = "card",
  see_punchline_button = "see_punchline_button",
  article_button = "article_button",
}

enum JOKES_TRACKING_EVENTS {
  joke_card_click = "joke_card_click",
  next_joke_button_click = "next_joke_button_click",
}

enum WORDS_TRACKING_EVENTS {
  word_grid_click = "word_grid_click",
  play_sound = "play_sound",
  next_word = "next_word",
  answer_clicked = "answer_clicked",
  next_question_click = "next_question_click",
  see_results_click = "see_results_click",
  next_quiz_click = "next_quiz_click",
  featured_quiz_click = "featured_quiz_click",
  completed_quiz = "completed_quiz",
  question_answered = "question_answered",
  new_quiz_click = "new_quiz_click",
}

enum INFO_TRACKING_EVENTS {
  editors_pick_article_click = "editors_pick_article_click",
  editors_pick_article = "editors_pick_article",
}

enum RECIPES_TRACKING_EVENTS {
  print_click = "print_click",
  recipe_tile_click = "recipe_tile_click",
  explore_more_tile_click = "explore_more_tile_click",
  download_click = "download_click",
  pinterest_share_click = "pinterest_share_click",
  recipe_tag_click = "recipe_tag_click",
  chicory_audit_view = "chicory_audit_view",
  chicory_audit_click = "chicory_audit_click",
  chicory_audit_grocer = "chicory_audit_grocer",
  chicory_button_click = "chicory_button_click",
  image_carousel_nav = "image_carousel_nav",
  scroll_depth = "scroll_depth",
  scroll_depth_mobile = "scroll_depth_mobile",
  scroll_depth_desktop = "scroll_depth_desktop",
  promotional_carousel_click = "promotional_carousel_click",
  explore_more_scroll = "explore_more_scroll",
  scroll_for_more_click = "scroll_for_more_click",
  wk_accepted = "wk_accepted",
  wk_clicked = "wk_clicked",
  wk_closed = "wk_closed",
  wk_adtype = "wk_adtype",
  fuzzy_match = "fuzzy_match",
}

export enum COMMON_TRACKING_EVENTS {
  article_tile_click = "article_tile_click",
  click_display_ad = "click_display_ad",
  click_video_ad_bottom_right = "click_video_ad_bottom_right",
  click_tracked_link = "click_tracked_link",
  pageview = "pageview",
  subscribed = "subscribed",
  subscribe_modal_dismissed = "subscribe_modal_dismissed",
  facebook_share_click = "facebook_share_click",
  twitter_share_click = "twitter_share_click",
  navigation_bar_click = "navigation_bar_click",
  search_open = "search_open",
  subscribe_open = "subscribe_open",
  subscribe_submit = "subscribe_submit",
  subscribe_failed = "subscribe_failed",
  subscribe_form_valid = "subscribe_form_valid",
  subscribe_impression = "subscribe_impression",
  sharesheet_button_click = "sharesheet_button_click",
  sharesheet_success = "sharesheet_success",
  sharesheet_abort = "sharesheet_abort",
  sharesheet_failure = "sharesheet_failure",
  social_embed_click = "social_embed_click",
  scroll_depth = "scroll_depth",
  ad_div_impression = "ad_div_impression",
  social_media_button_click = "social_media_button_click",
  recommended_article_click = "recommended_article_click",
  most_read_article_click = "most_read_article_click",
}

export const TRACKING_EVENTS = {
  ...WORDS_TRACKING_EVENTS,
  ...JOKES_TRACKING_EVENTS,
  ...INFO_TRACKING_EVENTS,
  ...RECIPES_TRACKING_EVENTS,
  ...COMMON_TRACKING_EVENTS,
};
export type TRACKING_EVENTS = typeof TRACKING_EVENTS;

export enum STATSIG_LOCATION_RECIPES {
  recipe = "recipe",
  category = "category",
}

export enum STATSIG_LOCATION_JOKES {
  joke = "joke",
  category = "category",
  categories = "categories",
  about = "about",
  submit = "submit",
}

export enum STATSIG_LOCATION_WORDS {
  word = "word",
}

export enum STATSIG_LOCATION_COMMON {
  home = "home",
  tag = "tag",
}

export const STATSIG_LOCATION = {
  ...STATSIG_LOCATION_RECIPES,
  ...STATSIG_LOCATION_JOKES,
  ...STATSIG_LOCATION_WORDS,
  ...STATSIG_LOCATION_COMMON,
};

export const STATSIG_METADATA = {
  brand: brand.id,
};

/*
 * NOTE: list of additional metadata key/values,
 * whenever there is a request to handle custom field, we would have to extend current interface,
 * */
export interface AdditionalMetadata {
  answer?: string;
  ad_slot?: string;
  index?: string;
  signup_location?: string;
  click_location?: string;
  exit_action?: string;
  banner_type?: string;
  tag_name?: string;
  direction?: string;
  scroll_depth?: string;
  render_location_index?: string;
  viewed_item?: string;
  adType?: string;
  tile_type?: string;
  clicked_slug?: string;
  location?: string;
  question?: string;
}

export interface InitStatsig {
  page_title: string;
}

export function logStatsig(
  statsigEvent: string,
  additionalMetadata?: AdditionalMetadata,
) {
  const currentLocation = location.pathname.split("/")[1] || "home";
  const statsigMetadata = {
    ...STATSIG_METADATA,
    ...{
      answer: additionalMetadata?.answer,
      ad_slot: additionalMetadata?.ad_slot,
      banner_type: additionalMetadata?.banner_type,
      click_location: additionalMetadata?.click_location,
      exit_action: additionalMetadata?.exit_action,
      index: additionalMetadata?.index,
      signup_location: additionalMetadata?.signup_location,
      tag_name: additionalMetadata?.tag_name,
      scroll_depth: additionalMetadata?.scroll_depth,
      render_location_index: additionalMetadata?.render_location_index,
      viewed_item: additionalMetadata?.viewed_item,
      tile_type: additionalMetadata?.tile_type,
      location: window.location.pathname,
      clicked_slug: window.location.pathname,
    },
    ...initStatsigObject,
    ...getUtmParamsForStatsig(),
  };

  Statsig.logEvent(
    statsigEvent,
    (STATSIG_LOCATION as { [key: string]: string })[currentLocation],
    statsigMetadata as { [key: string]: string },
  );

  log(
    { action: statsigEvent },
    {
      location: (STATSIG_LOCATION as { [key: string]: string })[
        currentLocation
      ],
    },
    { destination: "SS" },
    { metadata: statsigMetadata as { [key: string]: string } },
  );
}

export function logStatsigUpdateState(initObject: InitStatsig) {
  initStatsigObject = initObject;
}
